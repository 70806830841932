// @ts-nocheck
import React, { FC, Children, cloneElement, isValidElement } from 'react';
import ThreadsGrid from 'plaid-threads/Grid';
import cx from 'classnames';
import { Grid as GridProps } from './Grid.types';

const Grid: FC<GridProps> = ({
  children,
  useThreadsGrid = true,
  excludeGridContainerClass = false,
  excludeGridClass = false,
  includeGridMarginClass = false,
  noPadding = false,
  className = '',
  containerClassName = '',
  ...props
}) => {
  return useThreadsGrid ? (
    <ThreadsGrid
      noPadding={noPadding}
      className={className}
      containerClassName={containerClassName}
      testId='threads-grid-container'
      {...props}
    >
      {Children.toArray(children).map((child, idx) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            useThreadsGrid: true,
            key: `threads-grid-child-${idx}`,
          });
        }
        return child;
      })}
    </ThreadsGrid>
  ) : (
    <div
      className={cx(
        !excludeGridContainerClass && 'grid-container',
        containerClassName,
      )}
      data-testid='foundation-grid-container'
    >
      <div
        className={cx(
          !excludeGridClass && 'grid-x',
          includeGridMarginClass && 'grid-margin-x',
          className,
        )}
        data-testid='foundation-grid'
      >
        {Children.toArray(children).map((child, idx) => {
          if (isValidElement(child)) {
            return cloneElement(child, {
              useThreadsGrid: false,
              key: `foundation-grid-child-${idx}`,
            });
          }
          return child;
        })}
      </div>
    </div>
  );
};

export default Grid;
