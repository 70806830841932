import React, { cloneElement, isValidElement, useState } from 'react';
import cx from 'classnames';
import { string } from 'yup';

// components
import {
  SelectWithController,
  TextArea,
  TextInput,
  Button,
} from 'src/components/Inputs';
import { MarketoForm, GdprFields, CtaFormWrapper } from 'src/components/Forms';
import { RichTextRenderer } from 'src/components/RichTextRenderer';
import { Grid, Column } from 'src/components/Layout/Grid';

// constants
import { Cta as CtaProps, CtaTypes } from './cta.types';

// contexts
import { usePageLocale } from 'src/contexts';

// helpers
import { getRequiredMessage, getStaticData } from 'src/lib/utils';

// styles
import styles from './cta.module.scss';

const Cta = ({
  isDarkMode = false,
  'with-background': withBackground = false,
  'stacked-center': stackedCenter = false,
  'header-class': headerClass = '',
  title,
  subtext = '',
  'cta-link': ctaLink = '',
  'cta-text': ctaText = '',
  'with-subtext': withSubtext = false,
  'subtext-class': subtextClass = '',
  id = 'contactForm',
  image = { src: '', alt: '' },
  type = null,
  'include-phone': includePhone = false,
  'action-text': actionText = 'Contact sales',
  background = false,
  emailLabelOverride = 'Company email',
  companyLabelOverride = 'Company name',
  'marketo-key': marketoKey,
  includeCompany = true,
  includeJobTitle = false,
  includeCommentsField = false,
  includeDefaultFields = true,
  includeNames = true,
  includePxFieldset = false,
  bullets = [],
  children = null,
  disclaimer = '',
  trackingId = 'MARKETO_FORM',
  postSubmitContent = null,
  hasOnPagePostSubmitContent = false,
  isBodyForm = false,
  shouldForward = true,
  marketoFormBtnUrl,
  redirectOnSubmit,
  redirectUrl,
  formType,
  isNewGrid = false,
}: CtaProps): React.ReactElement => {
  const locale = usePageLocale();
  const { formData } = getStaticData({ locale, filePath: 'form' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const shouldRenderPostSubmitContent =
    hasOnPagePostSubmitContent && isFormSubmitted;
  // inject children with props for use in form
  const childrenWithProps = ({ control, errors, register, setValue }) => {
    return React.Children.map(children, (child) => {
      // checking if child is invalid for safety, also avoids a typescript error
      if (!isValidElement(child)) {
        return child;
      }
      const props = {
        register: register,
        required: child.props.errorMessage,
        errorMessage: errors[child.props.name]?.message,
        control: control,
        setValue: setValue,
        isDarkMode: isDarkMode,
      };
      // Note: this is will be deprecated with SITE-2278
      return cloneElement(child, props);
    });
  };

  return (
    <div
      id={id}
      data-testid='lead-section'
      className={cx('cta-section', {
        'cta-section--dark background background--color-black1000': isDarkMode,
        'section-callout': withBackground,
      })}
    >
      <div
        className={cx('grid-container', {
          [styles.gridContainerWide]: isNewGrid,
        })}
      >
        {stackedCenter ? (
          <div className='grid-x align-center text-center cta-stacked-center'>
            <div className='cell medium-10 large-8'>
              <h2 className={headerClass}>{title}</h2>
              <h4>
                <span>{subtext}</span>
              </h4>
              <div className='grid-x align-center'>
                <div className='cell medium-6 large-4'>
                  {ctaText && (
                    <Button
                      className='button-cta'
                      href={`${ctaLink}`}
                      fullWidth
                    >
                      {ctaText}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <CtaFormWrapper
            {...{
              bullets,
              headerClass,
              image,
              isBodyForm,
              isNewGrid,
              subtext,
              subtextClass,
              title,
              withSubtext,
            }}
          >
            {/* TODO: decouple form from Cta https://jira.plaid.com/browse/SITE-2278 */}
            {type === CtaTypes.WITH_BUTTONS ? (
              <div
                className='button-group button-group-justified'
                data-testid='cta-with-buttons-container'
              >
                <Button
                  className='button-cta'
                  href='//dashboard.plaid.com/signup'
                >
                  Sign up
                </Button>
                <Button
                  className='button-cta'
                  href='//dashboard.plaid.com/contact'
                  secondary
                >
                  Contact Sales
                </Button>
              </div>
            ) : (
              <>
                {shouldRenderPostSubmitContent ? (
                  postSubmitContent && postSubmitContent
                ) : (
                  <MarketoForm
                    {...{
                      actionText,
                      companyLabelOverride,
                      emailLabelOverride,
                      children,
                      includeDefaultFields,
                      includePhone,
                      isDarkMode,
                      marketoKey,
                      trackingId,
                      locale,
                    }}
                    formType={formType}
                    redirectUrl={
                      // we don't pass a url if the form should stay on the page
                      // otherwise we try to pass the redirect url from gated content
                      // or the marketo form button so that the user gets a predictable experience
                      hasOnPagePostSubmitContent
                        ? undefined
                        : redirectOnSubmit
                        ? redirectUrl
                        : marketoFormBtnUrl
                    }
                    shouldForward={shouldForward}
                    onSubmit={() => {
                      setIsFormSubmitted(true);
                    }}
                    render={({ control, register, errors, setValue }) => {
                      return (
                        <>
                          {includeDefaultFields && (
                            <>
                              {includeNames && (
                                <div className='grid-container full'>
                                  <div className='grid-x form-group'>
                                    <div className='cell small-12 medium-6'>
                                      <TextInput
                                        label={formData.firstName.label}
                                        id={formData.firstName.name}
                                        name={formData.firstName.name}
                                        describedby={
                                          formData.firstName.describedby
                                        }
                                        isDarkMode={isDarkMode}
                                        errorMessage={
                                          errors?.[formData.firstName.name]
                                            ?.message
                                        }
                                        register={register}
                                        required={getRequiredMessage(
                                          formData.firstName.label,
                                        )}
                                        autoComplete={
                                          formData.firstName.autoComplete
                                        }
                                      />
                                    </div>
                                    <div className='cell small-12 medium-6'>
                                      <TextInput
                                        label={formData.lastName.label}
                                        id={formData.lastName.name}
                                        name={formData.lastName.name}
                                        describedby={
                                          formData.lastName.describedby
                                        }
                                        isDarkMode={isDarkMode}
                                        errorMessage={
                                          errors?.[formData.lastName.name]
                                            ?.message
                                        }
                                        register={register}
                                        required={getRequiredMessage(
                                          formData.lastName.label,
                                        )}
                                        autoComplete={
                                          formData.lastName.autoComplete
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <TextInput
                                label={emailLabelOverride}
                                id={formData.email.name}
                                name={formData.email.name}
                                describedby={formData.email.describedby}
                                isDarkMode={isDarkMode}
                                errorMessage={errors?.Email?.message}
                                register={register}
                                pattern={{
                                  value: string().email().tests[0].OPTIONS
                                    .params.regex,
                                  message: formData.email.invalidMessage,
                                }}
                                required={getRequiredMessage(
                                  formData.email.label,
                                )}
                                autoComplete={formData.email.autoComplete}
                              />
                              {includeCompany && (
                                <TextInput
                                  label={companyLabelOverride}
                                  id={formData.company.name}
                                  name={formData.company.name}
                                  describedby={formData.company.describedby}
                                  isDarkMode={isDarkMode}
                                  errorMessage={
                                    errors?.[formData.company.name]?.message
                                  }
                                  register={register}
                                  required={getRequiredMessage(
                                    formData.company.label,
                                  )}
                                  autoComplete={formData.company.autoComplete}
                                />
                              )}
                              {/*
                              TODO: Threads phone input. Currently bugged:
                              https://github.plaid.com/pages/plaid/threads/?path=/story/inputs-phone-input--basic-example
                            */}
                              {includePhone && (
                                <TextInput
                                  label={formData.phone.label}
                                  id={formData.phone.name}
                                  name={formData.phone.name}
                                  describedby={formData.phone.describedby}
                                  isDarkMode={isDarkMode}
                                  errorMessage={
                                    errors?.[formData.phone.name]?.message
                                  }
                                  register={register}
                                  autoComplete={formData.phone.autoComplete}
                                />
                              )}

                              {includePxFieldset && (
                                <fieldset>
                                  <SelectWithController
                                    {...formData.categoryInput}
                                    errorMessage={
                                      errors?.[formData.categoryInput?.name]
                                        ?.message
                                    }
                                    required={getRequiredMessage(
                                      formData.categoryInput?.label,
                                    )}
                                    isDarkMode={isDarkMode}
                                    control={control}
                                    register={register}
                                    setValue={setValue}
                                  />
                                  <TextInput
                                    {...formData.coreProviderInput}
                                    errorMessage={
                                      errors?.[formData.coreProviderInput?.name]
                                        ?.message
                                    }
                                    required={getRequiredMessage(
                                      formData.coreProviderInput?.label,
                                    )}
                                    isDarkMode={isDarkMode}
                                    register={register}
                                  />
                                  <TextInput
                                    {...formData.bankingProviderInput}
                                    errorMessage={
                                      errors?.[
                                        formData.bankingProviderInput?.name
                                      ]?.message
                                    }
                                    required={getRequiredMessage(
                                      formData.bankingProviderInput?.label,
                                    )}
                                    isDarkMode={isDarkMode}
                                    register={register}
                                  />
                                </fieldset>
                              )}

                              {includeJobTitle && (
                                <TextInput
                                  label={formData.jobTitle?.label}
                                  id={formData.jobTitle?.name}
                                  name={formData.jobTitle?.name}
                                  describedby={formData.jobTitle?.describedby}
                                  errorMessage={
                                    errors?.[formData.jobTitle?.name]?.message
                                  }
                                  register={register}
                                  required={getRequiredMessage(
                                    formData.jobTitle?.label,
                                  )}
                                  autoComplete={formData.jobTitle?.autoComplete}
                                  isDarkMode={isDarkMode}
                                />
                              )}
                              {includeCommentsField && (
                                <TextArea
                                  {...formData.comments}
                                  isDarkMode={isDarkMode}
                                  errorMessage={
                                    errors?.[formData.comments?.name]?.message
                                  }
                                  required={getRequiredMessage(
                                    formData.comments?.label,
                                  )}
                                  register={register}
                                />
                              )}
                            </>
                          )}
                          {childrenWithProps({
                            control,
                            register,
                            errors,
                            setValue,
                          })}
                          <GdprFields
                            control={control}
                            errors={errors}
                            isDarkMode={isDarkMode}
                            register={register}
                            setValue={setValue}
                          />
                          <Grid
                            noPadding
                            noMarginRightMedium
                            noMarginRightLarge
                            useThreadsGrid
                          >
                            <Column medium={isBodyForm ? 6 : 12} allowOverflow>
                              <Button
                                className='button-cta mb1'
                                type='submit'
                                value={actionText}
                                invertedColors={isDarkMode}
                                fullWidth
                              >
                                {actionText}
                              </Button>
                            </Column>
                          </Grid>
                          {disclaimer ? (
                            typeof disclaimer === 'string' ? (
                              <p
                                data-testid='disclaimer'
                                className='contact-form__disclaimer'
                                dangerouslySetInnerHTML={{
                                  __html: disclaimer,
                                }}
                              />
                            ) : (
                              <RichTextRenderer content={disclaimer} />
                            )
                          ) : // Don't use fallback disclaimer text on Body Form CTA instances.
                          !isBodyForm ? (
                            <p
                              data-testid='disclaimer'
                              className='contact-form__disclaimer'
                              dangerouslySetInnerHTML={{
                                __html: formData.disclaimer,
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </>
                      );
                    }}
                  />
                )}
              </>
            )}
          </CtaFormWrapper>
        )}
        {background && <div className='cta-background'></div>}
      </div>
    </div>
  );
};

export default Cta;
