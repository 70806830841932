import React, { useState } from 'react';
import { string } from 'yup';

import { MarketoForm, GdprFields } from 'src/components/Forms';
import {
  TextArea,
  TextInput,
  Button,
  SelectWithController,
} from 'src/components/Inputs';
import { FormSectionTextInput } from './FormSectionTextInput';
import { FormSectionHopInCode } from './FormSectionHopInCode';
import { FormSectionPxFields } from './FormSectionPxFields';
import { FormSectionTicketType } from './FormSectionTicketType';
import { FormSectionNameFields } from './FormSectionNameFields';
import { FormSectionSolutionsFields } from './FormSectionSolutionsFields';
import { FormSectionWaitList } from './FormSectionWaitList';
import { FormSectionCovidWaiver } from './FormSectionCovidWaiver';
import { FormSectionDisclaimer } from './FormSectionDisclaimer';
import { FormSectionRichDescription } from './FormSectionRichDescription';

// helpers
import { usePageLocale } from 'src/contexts';
import { getStaticData, getRequiredMessage } from 'src/lib/utils';

import { Props } from './FormSection.types';

const FormSection: React.FC<Props> = ({
  handleFormSubmit,
  setIsFormSubmitted,
  showDescription = true,
  ...props
}) => {
  const [state, setState] = useState({ ticketType: '', useCase: '' });

  const handleTicketTypeChange = ({ value }) => {
    setState((state) => {
      return { ...state, ticketType: value };
    });
  };

  const handleUseCaseOtherChange = ({ value }) => {
    setState((state) => {
      return { ...state, useCase: value };
    });
  };

  const locale = usePageLocale();
  const { formData } = getStaticData({ locale, filePath: 'form' });

  return (
    <>
      <FormSectionRichDescription
        body={props.formData.body}
        showDescription={showDescription}
      />
      <MarketoForm
        formType={props?.formType}
        redirectUrl={
          // we try the redirect url before falling back to marketo cms button href
          (props?.redirectOnSubmit && props?.redirectUrl) ||
          props.formData.marketoFormBtnUrl
        }
        marketoKey={props.formData['marketo-key']}
        locale={locale}
        onSubmit={() => {
          // TODO: https://jira.plaid.com/browse/SITE-3462
          // Decouple setIsFormSubmitted from Gated Content
          setIsFormSubmitted && setIsFormSubmitted(true);
          handleFormSubmit && handleFormSubmit();
        }}
        shouldForward={props.formData.shouldForward}
        trackingId={props.formData.trackingId}
        render={({
          control,
          register,
          errors,
          setValue,
          isSubmitting,
          clearErrors,
        }) => {
          return (
            <>
              {props.formData.includeNames !== false && (
                <FormSectionNameFields
                  formData={formData}
                  register={register}
                  errors={errors}
                />
              )}

              <FormSectionTextInput
                data={formData.email}
                register={register}
                errors={errors}
                required
                pattern={{
                  value: string().email().tests[0].OPTIONS.params.regex,
                  message: formData.email.invalidMessage,
                }}
              />

              {props.formData?.includeCompany && (
                <FormSectionTextInput
                  data={formData.company}
                  register={register}
                  errors={errors}
                  required
                />
              )}

              {props.formData?.includePhone && (
                <FormSectionTextInput
                  data={formData.phone}
                  register={register}
                  errors={errors}
                />
              )}

              {props.formData?.hopinIntegrationCode && (
                <FormSectionHopInCode
                  ticketType={state.ticketType}
                  register={register}
                  formData={props.formData}
                />
              )}

              {props.formData?.includePxFieldset && (
                <FormSectionPxFields
                  control={control}
                  formData={formData}
                  errors={errors}
                  setValue={setValue}
                  register={register}
                />
              )}

              {props.formData?.includeJobTitle && (
                <FormSectionTextInput
                  data={formData.jobTitle}
                  register={register}
                  errors={errors}
                />
              )}

              {props.formData?.includeSolutionsField && (
                <FormSectionSolutionsFields
                  formData={formData}
                  setValue={setValue}
                  errors={errors}
                  register={register}
                  clearErrors={clearErrors}
                />
              )}

              {props.formData?.includeCommentsField && (
                <TextArea
                  {...formData.comments}
                  errorMessage={errors?.[formData.comments.name]?.message}
                  required={getRequiredMessage(formData.comments.label)}
                  register={register}
                />
              )}

              <GdprFields
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
                excludeActiveCountries={props.formData?.excludeActiveCountries}
              />

              {props.formData?.includeTicketType && (
                <FormSectionTicketType
                  formData={formData}
                  register={register}
                  cmsProps={props}
                  errors={errors}
                  setValue={setValue}
                  control={control}
                  handleTicketTypeChange={handleTicketTypeChange}
                />
              )}

              {props.formData?.isWaitListSoldOut && (
                <FormSectionWaitList formData={formData} register={register} />
              )}

              {props.formData?.includeUseCase && (
                <SelectWithController
                  label={formData.plaidForumUseCase?.label}
                  name={formData.plaidForumUseCase?.name}
                  describedby={formData.plaidForumUseCase?.describedby}
                  isDarkMode={false}
                  id={formData.plaidForumUseCase?.name}
                  register={register}
                  required={getRequiredMessage(
                    formData.plaidForumUseCase?.label,
                  )}
                  errorMessage={
                    errors?.[formData.plaidForumUseCase?.name]?.message
                  }
                  setValue={setValue}
                  control={control}
                  options={formData.plaidForumUseCase.options}
                  onChange={handleUseCaseOtherChange}
                />
              )}

              {state.useCase === 'Other' && (
                <TextInput
                  label={formData.otherUseCaseExplained?.label}
                  id={formData.otherUseCaseExplained?.name}
                  name={formData.otherUseCaseExplained?.name}
                  describedby={formData.otherUseCaseExplained?.describedby}
                  required={getRequiredMessage(
                    formData.otherUseCaseExplained?.label,
                  )}
                  errorMessage={
                    errors?.[formData.otherUseCaseExplained?.name]?.message
                  }
                  register={register}
                />
              )}

              {props.formData?.includeTicketType &&
                state.ticketType === 'In person' && (
                  <FormSectionCovidWaiver
                    formData={formData}
                    register={register}
                  />
                )}

              <Button
                className='button-cta mb1'
                type='submit'
                value={props.formData?.actionText || 'Contact Sales'}
                fullWidth
                showLoader={isSubmitting}
                submitting={isSubmitting}
              >
                {isSubmitting ? (
                  <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Loading&hellip;</>
                ) : (
                  props.formData?.actionText || 'Contact Sales'
                )}
              </Button>

              <FormSectionDisclaimer formData={formData} cmsProps={props} />
            </>
          );
        }}
      />
    </>
  );
};

export default FormSection;
