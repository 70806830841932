import { getStaticData } from 'src/lib/utils';

export const getFormData = ({ locale }) => {
  const { formData } = getStaticData({ locale, filePath: 'form' });
  return Object.assign({}, formData, {
    marketoKey: 2006,
    actionText: 'Submit',
    comments: {
      component: 'TextArea',
      id: 'Plaid_Comments__c',
      name: 'Plaid_Comments__c',
      label: 'Comments',
      placeholder:
        'What are you building, and in what countries? Details are helpful!',
      describedby: 'details about what you are building',
      type: 'textarea',
    },
  });
};
