import React, { useState } from 'react';
import styled from '@mui/system/styled';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { IButton } from './Button.types';

// components
import { Link } from 'src/components/Inputs';
import { useTrack, trackingActions } from 'src/contexts/AnalyticsContext';
import { ConditionalWrapper } from 'src/components/helpers';
import AnimatedCaret from './AnimatedCaret';

const StyledButton = styled(ButtonUnstyled)({});
export const BACKGROUND_COLORS = ['blue800', 'green800', 'red800', 'purple800'];

const Button: React.FC<IButton> = ({
  isCaretActive = true,
  hasAnimatedCaret = true,
  children,
  href,
  variant = 'contained',
  trackingId = '',
  onClick = (event) => {
    return undefined;
  },
  sx,
  ...props
}) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: props?.sys?.id,
  });
  const [backgroundColor, setBackgroundColor] = useState('black');
  const [isHover, setIsHover] = useState(false);

  const track = useTrack();
  const isTextButton = variant === 'text';
  const styles = {
    outlined: {
      backgroundColor: 'white',
      color: 'black',
      '&:hover, :focus': {
        color: 'blue900',
        borderColor: 'blue900',
      },
      '&:active': {
        color: 'blue1000',
        borderColor: 'blue1000',
      },
    },
    text: {
      border: 'none',
      backgroundColor: 'transparent',
      color: 'black',
      '&:hover, :focus': {
        color: 'blue900',
      },
      '&:active': {
        color: 'blue1000',
      },
    },
  };

  const handleBackgroundColor = () => {
    const colorIndex = Math.floor(Math.random() * BACKGROUND_COLORS.length);
    setBackgroundColor(BACKGROUND_COLORS[colorIndex]);
  };

  const handleAnimation = () => {
    handleBackgroundColor();
    setIsHover(true);
  };

  const handleClick = (event) => {
    if (trackingId) {
      track({
        type: trackingActions.BUTTON_CLICKED,
        payload: { trackingId, event, currentTarget: event.currentTarget },
      });
    }
    if (onClick && !href) {
      onClick(event);
    }
  };

  const resetAnimation = () => {
    setBackgroundColor('black');
    setIsHover(false);
  };

  return (
    <ConditionalWrapper
      condition={href}
      wrapper={(children) => {
        // we render the button as a hyperlink when href prop is present
        // we also need to wrap the anchor in our specialized Link component
        // The Link component wrapper will handle localization and tracking
        return <Link href={href}>{children}</Link>;
      }}
    >
      <StyledButton
        slots={{ root: href ? 'a' : 'button' }}
        href={href}
        role='button'
        data-name='StyledButton'
        onClick={(e) => {
          return handleClick(e);
        }}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: backgroundColor || 'black',
          color: 'white',
          p: '1.6rem',
          fontSize: '1.6rem',
          fontWeight: '600',
          lineHeight: '1.6rem',
          border: 1,
          borderColor: backgroundColor || 'black',
          borderRadius: '2px',
          transitionDuration: '100ms',
          transitionProperty: 'background-color, border-color, color',
          transitionTimingFunction: 'ease-in-out',
          '&:hover, :focus, :active': {
            color: 'white',
          },
          ...styles[variant],
          ...sx,
        }}
        onBlur={resetAnimation}
        onFocus={handleBackgroundColor}
        onMouseEnter={handleAnimation}
        onMouseLeave={resetAnimation}
        {...inspectorProps({ fieldId: 'text' })}
        {...props}
      >
        {children}
        {hasAnimatedCaret && !isTextButton && (
          <AnimatedCaret isCaretActive={isCaretActive} isHover={isHover} />
        )}
      </StyledButton>
    </ConditionalWrapper>
  );
};

export default Button;
