import * as React from 'react';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { Btn as Button } from 'src/components-v2/Inputs';
import { targetMap } from 'src/lib/constants';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import { Box, Column, Stack } from 'src/components-v2/Layout';
import { Wrapper } from 'src/containers';
import { Image } from 'src/components-v2/Media';
import { BackgroundContext } from 'src/contexts';

import cx from 'classnames';
import styles from './CmsCtaAssetBanner.module.scss';

export const CmsCtaAssetBanner = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor?.toLowerCase() || 'white';
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  const assetData = fields?.asset?.fields?.largeImage?.fields?.file;

  return (
    <Wrapper
      sx={{
        backgroundColor: bgColor,
        ...fields?.styles,
      }}
      component='section'
      {...inspectorProps({ fieldId: 'internalTitle' })}
    >
      <Column lg={20} lgOffset={2} sm={24}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          className={cx(styles.CmsCtaAssetBanner)}
        >
          <Box component='div' className={cx(styles.imageWrapper)}>
            {assetData && (
              <Image
                src={'https:' + assetData.url}
                width={assetData.details.image.width}
                height={assetData.details.height}
                alt='.'
              />
            )}
          </Box>
          <Box component='div' className={cx(styles.contentWrapper)}>
            <BackgroundContext.Provider // text inside this component should always be dark
              value='white'
            >
              <RichTextSwitch
                sys={sys}
                content={fields?.body}
                nodes={{
                  h2: {
                    variant: 'h4',
                    component: 'h2',
                    fieldId: 'body',
                  },
                  p: {
                    variant: 'p2',
                    component: 'p',
                    fieldId: 'body',
                  },
                }}
              />
              <Button
                variant={'tertiary'}
                href={fields?.cta?.fields?.url || ''}
                target={targetMap[fields?.cta?.fields?.target] || '_self'}
                trackingId={fields?.cta?.fields?.trackingId || ''}
                sys={fields?.cta?.sys}
                className={styles.ctaLink}
                isInverted={true}
              >
                <span>{fields?.cta?.fields?.text}</span>
              </Button>
            </BackgroundContext.Provider>
          </Box>
        </Stack>
      </Column>
    </Wrapper>
  );
};
